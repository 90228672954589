@use "../../scss/" as *;

.loginPage{
    width: 100vw;
    height: 100%;
    background-color: $gray-100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .loginHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 446rem;
        grid-gap: 8rem;
        margin-bottom: 36rem;
        p{
            text-align: center;
            @include fnt(16, 20, $weight: 500, $font: $fontText, $color: $gray-800)
        }
    }

    .loginFooter{
        display: flex;
        align-items: center;
        grid-gap: 6rem;
        margin-top: 32rem;
        .indicatorText{
            @include fnt(14, 20, $weight: 500, $font: $fontUI, $color: $gray-400)
        }
    }
}

.conatainerLogin{
    display: flex;
    flex-direction: column;
    gap: 20rem;
}

.verificatedContainer{
    background-color: $gray-100;
    border-radius: 24rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 280rem;
    margin-top: 44rem;

    .containerVerif{
        width: 300rem;
        display: flex;
        flex-direction: column;
        gap: 8rem;
    }

    img{
        width: 40rem;
        height: 40rem;
    }

    h1{
        @include fnt(40, 40, $weight: 700, $color: $gray-800, $font:$fontUI)
    }
    h4{
        @include fnt(16, 24, $weight: 400, $color: $gray-500, $font:$fontUI)
    }
}