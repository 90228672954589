@use "../../scss/" as *;

.FormSelector {
    width: 100%;

    .formContainerSelector {
        display: flex;
        align-items: center;
        position: relative;

        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .downImg {
            position: absolute;
            right: 12rem;
            transition: 0.2s linear;

            &.upImg {
                transform: rotateZ(-180deg);
                transition: 0.2s linear;
            }
        }
    }

    .formSelector {
        width: 100%;
        height: 36rem;
        border: 1rem solid rgba(0, 16, 61, 0.12);
        border-radius: 4rem;
        font-size: 14rem;
        padding: 8rem 35rem 8rem 12rem;
        outline: none;
        transition: 0.2s linear;

        &.paddingIco {
            padding: 8rem 30rem;
        }

        &:focus {
            outline: none;
            box-shadow:
                0px 0px 0px 4px #f2f4f7,
                0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        }

        &.error {
            border: 1rem solid #fda29b;

            &:focus {
                outline: none;
                box-shadow:
                    0px 0px 0px 4px #fee4e2,
                    0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            }
        }

        &.friendly {
            border-radius: 8rem;
            padding: 10rem 12rem;
            height: 44rem;
        }
    }

    .optionsContainer::-webkit-scrollbar {
        /* Стилизация для Chrome, Safari и Opera */
        display: none;
    }
    .warningMessage {
        position: absolute;
    }
    .optionsContainer {
        border-radius: 8rem;
        border: 1rem $gray-200;
        background: $white;
        padding: 4rem;
        box-shadow:
            0px 4rem 6rem -2rem rgba(16, 24, 40, 0.03),
            0px 12px 16px -4px rgba(16, 24, 40, 0.08);
        max-height: 360rem;
        position: absolute;
        z-index: 10;
        width: 100%;
        overflow-y: scroll;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer и Edge */
        padding-bottom: 0;

        .optionsItem {
            padding: 10rem 8rem;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:hover {
                background: $gray-100;
            }
            &.focus {
                background: $gray-50;
            }

            p {
                color: $gray-900;
                font-family: $fontUI;
                font-size: 16rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24rem; /* 150% */
                margin: 0;
            }
        }
    }

    &.bigInput {
        margin-top: 0;

        .formSelector {
            border-radius: 8rem;
            padding: 12rem 14rem;
        }
    }
}
