@use "../../scss/" as *;

.footer{
    width: 100%;
    height: 100%;
    background-color: $gray-700;
    margin-top: 144rem;
    @include tablet {
       height: auto;
       margin-top: 77rem;
    }
    @include mobile {
       height: auto;
       margin-top: 44rem;
    }
   

    .footerContainer{
        width: 1296rem;
        margin: 0 auto;
        height: 100%;
        position: relative;
        display: grid;
        grid-template-columns: 1fr 135rem 300rem;
        gap: 200rem;
        justify-content: center;
        align-items: start;
        padding: 30rem 0;

        @include laptop {
            width: 100%;
            padding: 24rem;
        }
        @include tablet {
            display: flex;
            justify-content: space-between;
            width: 100%;
            gap: 0;
            object{
                width: 50%;
                height:auto;
            }
        }
        @include mobile{
            display: flex;
            flex-direction: column;
            gap: 24rem;
        }
    }

    .headerLogoText{
        display: flex;
        align-items: center;
        z-index: 9;
        gap: 12rem;

        .logoText{
            width: 201rem;
            @include fnt(14, 18, $weight: 600, $color: $white);
        }
    }

 

    .footerBg{
        position: absolute;
        bottom: 0;

        @include mobile{
            display: none;
        }
    }

    .navFooter{
        display: flex;
        flex-direction: column;
        gap: 12rem;
        z-index: 9;
       
        .navItem{
            @include fnt(16, 20, $weight: 400, $color: $white);
            transition: .1s ease-in-out;

            &:hover{
                color: $orange-dark-500;
            }
        }
    }

    .footerInfoContainer{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 240rem;

        @include mobile{
            height: auto;
            align-items: start;
            gap: 8rem;
        }

        .infoFooter{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 8rem;

            @include mobile{
                align-items: start;
            }

            .address{
                @include fnt(16, 24, $weight: 400, $color: $white);
            }
            .tel{
                @include fnt(16, 16, $weight: 700, $color: $white);

                transition: .1s ease-in-out;

                &:hover{
                    color: $orange-dark-500;
                }
            }
        }
        .linkIndicator{
            display: flex;
            align-items: center;
            gap: 8rem;
            opacity: .5;
            transition: .1s ease-in-out;

            &:hover{
                opacity: 1;
            }

            p{
                @include fnt(16, 24, $weight: 700, $color: $white);
            }
        }
    }
}