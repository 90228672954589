@use "../../scss/" as *;

.containerVacation{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: $gray-500;
    border-radius: 12rem;
    padding: 24rem;
    margin-top: 54rem;

    @include tablet{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8rem;
    }

    .vacationTitle{
        @include fnt(24, 32, $weight: 700, $color: $white);
    }
    .vacationText{
        @include fnt(16, 24, $weight: 400, $color: $white);
    }

    .whiteButtonVaccation{
        background-color: $white;
        border-radius: 8rem;
        height: 44rem;

        &:hover{
            background-color: $gray-100;
        }

        @include fnt(16, 20, $weight: 700, $color: $gray-700);

        @include tablet{
            width: 100%;
        }
    }
}

.classModal{
    background-color: $gray-100 !important;
    max-width: 520rem !important;
}

.classBgModal{
    background-color: #101828 !important;
    opacity: .2 !important;
}

.modalContainer{
    margin: 0 auto;
    width: 300rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8rem;

    .formModal{
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }

    .titleModal{
        @include fnt(32, 40, $weight: 700, $color: $gray-800);
    }
    .textModal{
        @include fnt(16, 20, $weight: 400, $color: $gray-500);
    }
    .subtextModal{
        margin-top: 8rem;
        @include fnt(14, 20, $weight: 400, $color: $gray-400);
    }

    .modalHeader{
        margin-bottom: 16rem;
    }

    button{
        width: 100%;
        margin-top: 16rem;
    }
}