@use "../../scss/" as *;

.sectionCardContainer__two{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24rem;
    margin-top: 20rem;

    p{
        @include fnt(16, 24, $weight: 400, $font: $fontText, $color: $gray-900);
    }

    @include tablet{
        display: flex;
        flex-wrap: wrap;
    }
}