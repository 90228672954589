@use "../../scss/" as *;

.pageContainerTitle{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 8rem;
    margin-top: 32rem;

    .backButton{
        background-color: inherit;
        @include fnt(14, 16, $weight: 400, $color: $orange-dark-500);
        display: flex;
        gap: 4rem;
        padding: 0;
    
        object{
            width: 16rem;
            height: 16rem;
            margin: 0;
        }
    
        &:hover{
            background-color: inherit;
            color: $orange-500;
        }
    }
}



.loginPage{
    width: 100vw;
    height: 100%;
    background-color: $gray-100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    .loginHeader{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 446rem;
        grid-gap: 8rem;
        margin-bottom: 36rem;
        p{
            text-align: center;
            @include fnt(16, 20, $weight: 500, $font: $fontText, $color: $gray-800)
        }
    }

    .loginFooter{
        display: flex;
        align-items: center;
        grid-gap: 6rem;
        margin-top: 32rem;
        .indicatorText{
            @include fnt(14, 20, $weight: 500, $font: $fontUI, $color: $gray-400)
        }
    }
}