@use "../../scss/" as *;

.avatarPicker{
    display: flex;
    align-items: center;
    gap: 12rem;

    .avatar{
        width: 96rem;
        height: 96rem;
        object-fit: cover;
        border-radius: 100%;
    }

    .avatarContainer{
        display: flex;
        flex-direction: column;
        gap: 8rem;

        h1{
            @include fnt(16, 24, $weight: 600, $color: $gray-900);
        }
        h3{
            @include fnt(12, 16, $weight: 400, $color: $gray-400);
        }

        .avatarButton{
            padding: 4rem;
            border-radius: 12rem;
            border: 1rem solid $gray-300;
            background-color: $white;
            @include fnt(12, 16, $weight: 400, $color: $gray-700);
            width: 102rem;

            &:hover{
                background-color: $gray-200;
            }
        }
    }
}