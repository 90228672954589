@use "../../scss/" as *;

.containerSearchNested{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 24rem 0 32rem;
}

.mySectionCardContainer{
    width: 100%;
    gap: 12rem;
    display: flex;
    flex-direction: column;
    margin-top: 16rem;
}

.cardEmploers{
    padding: 8rem 16rem;
    border-radius: 8rem;
    background-color: $gray-100;

    h3{
        @include fnt(16, 24, $weight: 600, $color: $gray-600)
    }
    h5{
        @include fnt(16, 24, $weight: 400, $color: $gray-500)
    }

    @include mobile{
        width: 100%;
    }
}

.containerTabs{
    display: flex;
    gap: 4rem;
    margin-top: 32rem;

    .whiteButton{
        padding: 8rem;
        background-color: $white;
 
        display: flex;
        flex-direction: row-reverse;
        padding-right: 32rem;
        border-radius: 8rem;
        border: 1rem solid $white;
        @include fnt(14, 20, $weight: 500, $color: $gray-500);

        .circleNumber{
            
            right: 6rem;
            left: inherit;
            background-color: $gray-100;
            border: 1rem solid $gray-300;
        }

        &.focus{
            border: 1rem solid $gray-300;
            @include fnt(14, 20, $weight: 500, $color: $gray-700);
        }
    }
}