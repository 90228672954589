@use "./variables" as *;
@mixin flex($aligin-item, $justify-content) {
    display: flex;
    align-items: $aligin-item;
    justify-content: $justify-content;
}

@mixin overlay {
    background-color: rgba($color: #000000, $alpha: 0.6);
}

@mixin fnt($size: 16, $height: 24, $color: $black, $weight: 400, $font: $fontUI) {
    font-family: $font;
    font-style: normal;
    font-weight: unset;
    font-variation-settings: "wght" $weight;
    font-size: #{$size}rem;
    line-height: #{$height}rem;
    color: $color;
}

@mixin size($width: 24, $height: false) {
    width: #{$width}rem;
    @if ($height) {
        height: #{$height}rem;
    }
    @else {
        height:#{$width}rem;
    }
}

@mixin scroll() {
    &::-webkit-scrollbar {
        width: 8rem;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 4rem;
        background-color: $gray-200;
    }
    &::-webkit-scrollbar-button {
        height: 4rem;
    }
    //scrollbar-gutter: stable;
    scrollbar-gutter: auto;
}