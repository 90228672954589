@use "../../scss/" as *;

.passwordRecoveryButton{
    display: flex;
    align-items: center;
    position: relative;
    gap: 6rem;
    cursor: pointer;

    @include fnt(14, 20, $color: $gray-400, $weight: 400, $font: $fontUI);

    .helpIco{
        width: 20rem;
        height: 20rem;
        pointer-events: all;
    }

    .helpMessage{
        bottom: 25rem;
        right: 0;
        width: fit-content;
        height: fit-content;
        padding: 8rem;
        border-radius: 8rem;
        position: absolute;
        opacity: 0;
        background-color: $gray-700;
       

        p{
            @include fnt(12, 20, $color: $white, $weight: 400, $font: $fontUI);
        }

        a{
            cursor: pointer;
            pointer-events: all;
            @include fnt(12, 20, $color: $orange-dark-500, $weight: 400, $font: $fontUI)
        }

        &.active{
            opacity: 1;
        }
    }
}