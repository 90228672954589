@use "../../scss/" as *;


.sectionCardContainer__center{
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
    gap: 24rem;
    margin: 20rem auto;
    width: 636rem;

    .titlePage{
        @include fnt(32, 40, $weight: 500, $font: $fontText, $color: $gray-800);
        margin: 0;
    }
    h5{
        @include fnt(14, 20, $weight: 400, $font: $fontText, $color: $gray-500);
        margin: 0;
    }

    .img{
        width: 100%;
        height: 398rem;
        object-fit: cover;
    }

    p{
        @include fnt(16, 28, $weight: 400, $font: $fontText, $color: $gray-800);
    }
    a{
        color: $blue;
    }

    .newsContainer{
        width: 636rem;
    }

    
    @include tablet{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .newsContainer{
            width: 100%;
        }
    }
}