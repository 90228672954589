@use "../../scss/" as *;

.header{
    padding: 0;
    margin: 24rem 0 8rem 0;

    &.mobile{
        width: 100%;
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        padding: 12rem 16rem;
        margin: 0;
        background-color: $white;
    }
}

.containerHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .headerLogoText{
        display: flex;
        align-items: center;
        grid-gap: 12rem;
        cursor: pointer;
        z-index: 10;

        .logo{
            width: 38rem;
            height: 48rem;
        }
        .logoSvoi{
            width:  60rem;
            object-fit: contain;
            @include mobile{
                width: 40rem;
               height: 40rem;
            }
        }

        .logoText{
            @include fnt(14, 18, $weight: 500, $font: $fontText, $color: $gray-800);
            max-width: 201rem;

            @include mobile{
                @include fnt(12, 15, $weight: 500, $font: $fontText, $color: $gray-800);
            }
        }
        @include mobile{
            gap: 6rem;
        }
    }

    .userHeaderContainer{
        display: flex;
        align-items: center;
        grid-gap: 12rem;

        @include tablet{
            position: absolute;
        }

        .nav{
            display: flex;
            align-items: center;
            gap: 24rem;
            padding: 10rem 0;

            @include tablet {
                position:fixed;
                top: 0;
                left: 0;
                width: 100%;
                height: 0;
                z-index: 9;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                background-color: $white;
                opacity: 0;
                pointer-events: none;
                transition: .1s ease-in-out;
                border-radius: 0 0 12rem 12rem;
                padding: 0 8rem;
    
                &.active{
                    opacity: 1;
                    pointer-events: all;
                    height: fit-content;
                    gap: 6rem;
                    justify-content: flex-end;
                    padding: 8rem 16rem;
                    padding-top: 64rem;
                }
    
                a{
                    color: $white;
                    padding: 8rem 0;
                }
    
                .button__container{
                    background-color: inherit;
                    padding: 8rem 0;
                }
            }

            .navItem{
                @include fnt(14, 20, $weight: 400, $font: $fontText, $color: $gray-700);

                &.work{
                    color: $orange-dark-500;
                }
            }
        }

        .userName{
            text-align: end;
            @include fnt(14, 18, $weight: 400, $font: $fontText, $color: $gray-500);
            .lastname{
                @include fnt(14, 18, $weight: 600, $font: $fontText, $color: $gray-800);
            }
        }

        .whiteButton{
            flex-direction: row-reverse;
            grid-gap: 4rem;
            background-color: $white;
            border: 1rem solid $gray-300;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
            padding: 10rem 10rem 10rem 16rem;
            border-radius: 8rem;

            &.disable{
                background-color: $gray-200;
                pointer-events: none;
            }

            @include fnt(14, 20, $weight: 500, $font: $fontText, $color: $gray-700);
            object{
                margin-right: 0;
            }

            &:hover{
                background-color: $blue;
                color: $white;
            }

            @include tablet{
                padding: 8rem !important;
            }
        }
    }
}

.imageButton{
    border-radius: 100%;
    width: 20rem;
    height: 20rem;
}

.burgerMenu{
    content: '';
    width: 30rem;
    height: 3rem;
    background-color: $gray-900;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 10;

    &::before{
        position: absolute;
        top: -10rem;
        content: '';
        width: 30rem;
        height: 3rem;
        background-color: $gray-900;
    }
    &::after{
        position: absolute;
        bottom: -10rem;
        content: '';
        width: 30rem;
        height: 3rem;
        background-color: $gray-900;
    }

    &.active{
        transform: rotateZ(45deg);
        
    &::before{
        top: 0;
        transform: rotateZ(90deg);
    }
    &::after{
        display: none;
    }
    }
}

.whiteOrangeButton{
    display: flex;
    flex-direction: row-reverse;
    padding: 12rem 20rem !important;
    border: 1rem solid $orange;
    border-radius: 8rem;
    height: 44rem;
    @include fnt(16, 20, $weight: 500, $font: $fontText, $color: $orange-dark-500);
    background-color: inherit;
    gap: 8rem;

    &:hover{
        background-color: inherit;
    }

    object{
        margin: 0;
    }
}
.orangeButton{
    display: flex;
    flex-direction: row-reverse;
    padding: 12rem 20rem !important;
    border: 1rem solid $orange;
    border-radius: 8rem;
    height: 44rem;
    @include fnt(16, 20, $weight: 500, $font: $fontUI, $color: $white);
    background-color: $orange-dark-500;
    gap: 8rem;

    &:hover{
        background-color: inherit;
        color: $orange-dark-500;
    }

    @include tablet{
        background-color: $orange-dark-500 !important;
    }

    object{
        margin: 0;
    }
}
