@use "../../scss/" as *;

.newsCardContainer{
    display: flex;
    flex-direction: column;
    border-radius: 12rem;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;

    &:hover {
        .previewImage{
            transform: scale(1.05);
        }
    }

    @include laptop {
        width: 100%;
    }

 
    .previewImageContainer{
        width: 100%;
        height: 234rem;
        border-top-left-radius:12rem;
        border-top-right-radius:12rem;
        position: relative;
        overflow: hidden;

        .dataEvent{
            position: absolute;
            bottom: 12rem;
            left: 24rem;
            background-color: $white;
            width: fit-content;
            padding: 8rem 12rem;
            border-radius: 8rem;

            @include fnt(20, 20, $color: $gray-900, $weight: 600, $font: $fontUI);
        }
    
    }

    .previewImage{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease-in-out;
    }

    .infoNewsContainer{
        background-color: $gray-100;
        padding: 16rem 24rem 24rem;
        border-bottom-left-radius:12rem;
        border-bottom-right-radius:12rem;
        position: relative;
        height: 136rem;
    }

    .dateTimePublish{
        @include fnt(14, 20, $color: $gray-500, $weight: 400, $font: $fontUI);
    }

    .newsText{
        @include fnt(16, 24, $color: $gray-900, $weight: 400, $font: $fontUI);
    }

   
}