@use "../../scss/" as *;

.myCardHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .time{
        display: flex;
        align-items: center;
        gap: 4rem;

        @include fnt(14, 24, $weight:400, $color: $orange-dark-500);
    }
}

.returnToArchive{
    @include fnt(14, 24, $weight:400, $color: $orange-dark-500);
    background-color: inherit;
    line-height: 16rem;
    border-radius: 8rem;
    height: fit-content;

    &:hover{
        background-color: $orange-dark-500;
        color: $white;
    }
}

.cardContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16rem;
    background-color: $gray-100;
    border-radius: 12rem;
    padding: 24rem;
    cursor: pointer;

    &.modalActive{
        background-color: inherit;
        padding: 0;
        cursor: inherit;

        .cardTitleContainer{
            .cardTitle{
                @include fnt(32, 40, $weight:600, $color: $gray-900);
            }
            .cardPrice{
                @include fnt(24, 32, $weight:400, $color: $gray-900);
            }
        }

        .containerResponses{
            background-color: $gray-100;
        }
    }

    .cardContainerBody{
        display: flex;
        flex-direction: column;
        gap: 16rem;
    }

    .cardHeaderTag{
        display: flex;
        gap: 4rem;

        &::-webkit-scrollbar{
            display: none;
        }
    }

    .cardTag{
        width: fit-content;
        padding: 4rem 8rem;
        border-radius: 12rem;
        border: 1rem solid $orange-dark-500;
        @include fnt(12, 16, $weight:400, $color: $orange-dark-500);

        &__type{
            width: fit-content;
            padding: 4rem 8rem;
            border-radius: 12rem;
            border: 1rem solid $orange-dark-500;    
            background-color: $orange-dark-500;
            @include fnt(12, 16, $weight:400, $color: $white);
        }
    }

   

    .cardTitleContainer{
        display: flex;
        flex-direction: column;
        gap: 8rem;

        .cardTitle{
            @include fnt(20, 24, $weight:600, $color: $gray-900);
        }

        .cardPrice{
            @include fnt(20, 24, $weight:400, $color: $gray-900);
        }
    }

    .cardDescription{
        @include fnt(16, 24, $weight:400, $color: $gray-500);
        max-height: 120rem;
        overflow: hidden;
        word-wrap: break-word;
    }

    .containerResponsesApplicants{
        display: flex;
        gap: 4rem;
        flex-wrap: wrap;
        align-items: center;
    }

    .containerApplicants {
        background-color: $white;
        width: fit-content;
        padding: 8rem 24rem 8rem 8rem;
        border-radius: 36rem;
        display: flex;
        align-items: center;
      
        .responsesAvatarts {
          width: 40rem;
          height: 40rem;
          object-fit: cover;
          border-radius: 100%;
          margin-left: -8rem;
        }

        .numberResponses{
            width: 40rem;
            height: 40rem;
            display: flex;
            justify-content: center;
            align-items: center;

            @include fnt(14, 20, $weight:400, $color: $gray-500, $font: $fontText);
        }
    }
      
}

.containerApplicants{
    display: flex;
    gap: 8rem;
    align-items: center;

    .vacancyResponsesAvatarts{
        width: 60rem;
        height: 60rem;
        border-radius: 100%;
    }

    .applicantsInfo{
        display: flex;
        flex-direction: column;

        h1{
            @include fnt(16, 20, $weight:600, $color: $gray-900);
        }
        h2{
            @include fnt(14, 20, $weight:400, $color: $gray-500);
        }
        a{
            @include fnt(16, 20, $weight:400, $color: $orange-dark-500);
        }
    }
}

.footerCard{
    display: flex;
    flex-direction: column;

    .footerInfoCompany{
        display: flex;
        flex-direction: column;
        gap: 4rem;

        &.active{
            .cardOrgName{
                @include fnt(20, 24, $weight:600, $color: $orange-dark-500, $font: $fontText);
            }
    
            .cardFooterDescription{
                @include fnt(16, 20, $weight:400, $color: $gray-700, $font: $fontText);
            }
            .cardFooterLink{
                @include fnt(16, 20, $weight:400, $color: $orange-dark-500, $font: $fontText);
            }
        }

       
       
    }

    .cardOrgName{
        @include fnt(16, 20, $weight:400, $color: $orange-dark-500, $font: $fontText);
    }


    .footerCardCity{
        display: flex;
        margin: 4rem 0 16rem;
        justify-content: space-between;
        align-items: center;

        .cardCity{
            @include fnt(16, 20, $weight:400, $color: $gray-500, $font: $fontText);
        }
    }
}

.whiteButtonCard{
    width: 100%;
    padding: 12rem;
    border-radius: 8rem;
    background-color: $white;
    @include fnt(16, 20, $weight:500, $color: $gray-700, $font: $fontText);
    border: 1rem solid $gray-300;

    &:hover{
        background-color: $orange-500;
        color: $white;
    }
}

.buttonContainer{
    display: flex;
    gap: 4rem;

    .whiteButtonCard{
        &:hover{
            background-color: $gray-100;
            color: $gray-900;
        }
    }
    .orangeButtonCard{
        background-color: $white;
        @include fnt(16, 20, $weight: 500, $color: $orange-dark-500);
        width: 100%;
        border: 1rem solid $orange;
        border-radius: 8rem;
        &:hover{
            background-color: $gray-100;
        }
    }

    @include tablet {
        flex-direction: column;
    }
}