@use "../../scss/" as *;

.coverContainer{
    width: 100%;
    height: 288rem;
    position: relative;

    @include tablet{
        margin-top: 54rem;
    }
}

.bgCover{
    width: 100%;
    height: 288rem;
    z-index: -100;
    position: absolute;

    @include laptop {
        object-fit: cover;
    }
}

.coverContainTextImage{
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.coverTextContainer{
    height: 288rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .coverTitle{
        @include fnt(80, 96, $weight: 500, $color: $gray-800, $font: $fontText);

        @include tablet{
            @include fnt(60, 76, $weight: 500, $color: $gray-800, $font: $fontText);
        }
        @include mobile{
            @include fnt(40, 46, $weight: 500, $color: $gray-800, $font: $fontText);
        }
    }
    .coverText{
        margin-top: 8rem;
        @include fnt(20, 28, $weight: 400, $color: $gray-700);
    }
}

.imagePortrait{
    width: 341rem;
    height: 100%;

    @include tablet{
        display: none;
    }
}