@use "../../scss/" as *;

.containerFormJob{
    gap: 16rem;
    display: grid;
    flex-direction: column;

    @include tablet{
        display: flex;
    }
}

.titlePage{
    margin-bottom: 16rem;
    @include tablet{
       font-size: 34rem;
    }
    @include mobile{
       font-size: 24rem;
    }
}

.colInput-1 {
    grid-column: 1; 
}

.colInput-2 {
    grid-column: 2;
}

.colInput-3 {
    grid-column: 1 / span 2;
}