@use "../../scss/" as *;

.formContainer{
    padding: 40rem;
    background-color: $gray-100;
    border-radius: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16rem;
    margin-top: 44rem;

    @include mobile{
        padding: 20rem;
    }
}

.orangeBtnForm{
    background-color: $orange-dark-500;
    width: 100%;
    height: 44rem;
    padding: 12rem;
    border-radius: 8rem;

    &:hover{
        background-color: $orange-500;
    }
}

.formContainerField{
    width: 300rem;
    display: flex;
    flex-direction: column;
    gap: 16rem;

    .formTitle{
        @include fnt(40, 48, $weight: 500, $color: $gray-800);
    }
}
